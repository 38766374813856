<template>
  <div class="success-page__wrapper">
    <img
      src="@/shared/assets/success-tick.svg"
      alt="Success"
      class="success-page__icon"
    >
    <div class="success-page__subtitle sk-text-large-regular">
      <div>{{ $t('success_page.account_ready') }}</div>
      <div>{{ $t('success_page.redirect_message') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessPage',
  mounted() {
    setTimeout(() => {
      this.$emit('redirect-to-skello');
    }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.success-page {
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
  }

  &__icon {
    width: 64px;
    height: 64px;
  }

  &__subtitle {
    padding-top: 24px;
    color: $sk-success;
    text-align: center;
  }
}
</style>
