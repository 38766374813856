<template>
  <div>
    <div class="create-shop__label sk-text-large-semibold">
      {{ $t('create_shop.general_informations') }}
    </div>
    <div class="input-row__line-container">
      <div class="input-row--item">
        <SkInput
          ref="nameInput"
          v-model.trim="name"
          type="text"
          class="input-row--large"
          :label="$t('create_shop.shop_name_input')"
          :errored="!!nameErrorMessage"
          :error-message="nameErrorMessage"
          :debounce="handleNameChanges"
          :debounce-ms="300"
        />
        <div>
          <SkDropdown
            ref="companyNameDropdown"
            placement="bottom-start"
            y-offset="10px"
            :trigger="null"
          >
            <!-- eslint disabled because we need to have 2 menus in the same page -->
            <!-- but html ids are unique -->
            <!-- eslint-disable-next-line vue/v-slot-style -->
            <template v-slot:menu>
              <SuggestedCompanyRegistrations
                v-if="showCompanyNameDropdown"
                :suggested-companies="suggestedCompanyRegistrations"
                :search-input="name"
                :related-input="$refs.nameInput"
                @select-suggested-company="autofillBillingInfos"
              />
            </template>
          </SkDropdown>
        </div>
      </div>
      <div class="input-row--item">
        <SkInput
          ref="registrationNumberInput"
          v-model.trim="registrationNumber"
          class="input-row--large"
          type="text"
          :label="$t('fill_billing_info.billing_line.shop_registration_number_input')"
          :errored="!!registrationNumberErrorMessage"
          :error-message="registrationNumberErrorMessage"
          :debounce="handleAndValidateRegistrationNumber"
          :debounce-ms="300"
        />
        <SkDropdown
          ref="registrationNumberDropdown"
          placement="bottom-start"
          y-offset="10px"
          :trigger="null"
        >
          <!-- eslint disabled because we need to have 2 menus in the same page -->
          <!-- but html ids are unique -->
          <!-- eslint-disable-next-line vue/v-slot-style -->
          <template v-slot:menu>
            <SuggestedCompanyRegistrations
              v-if="showRegistrationNumberDropdown"
              :suggested-companies="suggestedCompanyRegistrations"
              :search-input="registrationNumber"
              :related-input="$refs.registrationNumberInput"
              @select-suggested-company="autofillBillingInfos"
            />
          </template>
        </SkDropdown>
      </div>
    </div>
    <div class="create-shop__body__input-row">
      <div class="billing-address__label sk-text-large-semibold">
        {{ billingAddressLabel }}
      </div>
      <div class="input-row__line-container">
        <SkInput
          v-model.trim="address"
          class="input-row__second-line"
          type="text"
          :label="$t('fill_billing_info.billing_line.address_input')"
          :errored="!!addressErrorMessage"
          :error-message="addressErrorMessage"
          :debounce="validateMandatoryField('address')"
          :debounce-ms="100"
        />
      </div>
      <div class="input-row__line-container">
        <SkInput
          v-model.trim="city"
          class="input-row--item"
          type="text"
          :label="$t('fill_billing_info.billing_line.city_input')"
          :errored="!!cityErrorMessage"
          :error-message="cityErrorMessage"
          :debounce="validateMandatoryField('city')"
          :debounce-ms="100"
        />
        <SkInput
          v-model.trim="zipcode"
          class="input-row--item"
          type="text"
          :label="$t('fill_billing_info.billing_line.zipcode_input')"
          :errored="!!zipcodeErrorMessage"
          :error-message="zipcodeErrorMessage"
          :debounce="validateMandatoryField('zipcode')"
          :debounce-ms="100"
        />
        <SkSelectV2
          v-model.trim="country"
          class="input-row--item"
          :options="countryOptions"
          :label="$t('fill_billing_info.billing_line.country_input')"
          :errored="!!countryErrorMessage"
          :error-message="countryErrorMessage"
          :debounce="validateMandatoryField('country')"
          :debounce-ms="100"
          :disabled="!isAllowedToUpdateCountryField"
        />
      </div>
    </div>
    <div v-if="!isBillingOnOrganisation">
      <div class="create-shop__label">
        <div class="sk-text-large-semibold">
          {{ $t('create_shop.payment_informations') }}
        </div>
        <div>
          <SkOroraTag
            v-if="hasAddedPayment && displayPaymentByCreditCard"
            variant="green"
          >
            {{ $t('create_shop.payment_added_tag') }}
          </SkOroraTag>
        </div>
      </div>
      <div>
        <div class="create-shop__payment">
          <SkOroraButton
            v-if="displayPaymentByCreditCard"
            class="create-shop__payment-button"
            variant="secondary"
            @click="openCheckout"
          >
            {{ paymentButtonLabel }}
          </SkOroraButton>

          <div
            v-else
            class="input-row__line-container"
          >
            <SkInput
              v-if="displayPaymentByIban"
              v-model.trim="ibanWithSpacesAllowed"
              type="text"
              :label="$t('fill_billing_info.billing_line.iban_input')"
              :errored="!!ibanErrorMessage"
              :error-message="ibanErrorMessage"
              :debounce="validateIban"
              :debounce-ms="100"
            />
            <SkInput
              v-model.trim="email"
              type="text"
              :label="$t('fill_billing_info.billing_line.email_input')"
              :errored="!!emailErrorMessage"
              :error-message="emailErrorMessage"
              :debounce="validateEmail"
              :debounce-ms="100"
            />
          </div>
        </div>

        <div class="create-shop__payment-label sk-text-medium-semibold">
          <ShieldWithCheckIcon
            :fill="$skColors.skBlue50"
            width="16"
            height="16"
          />
          <span>{{ $t('create_shop.secure_payment_label') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
  mapState,
  mapActions,
} from 'vuex';

import SuggestedCompanyRegistrations from '@components/BillingLineContent/SuggestedCompanyRegistrations';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import { removeWhiteSpaces } from '@/shared/utils/method_helper';
import {
  isValidSiret,
  isValidIban,
  isValidEmail,
} from '@/shared/utils/validators';
import { VUE_APP_CHARGEBEE_SITE } from '@/shared/config';
import { getNaf } from '@/shared/utils/naf';
import { COUNTRY_KEYS } from '@/shared/constants/country-keys';

export default {
  name: 'ShopDetailsForm',
  components: { SuggestedCompanyRegistrations },
  props: {
    shopPaymentInfos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      registrationNumberWithSpacesAllowed: null,
      addressErrorMessage: null,
      cityErrorMessage: null,
      zipcodeErrorMessage: null,
      countryErrorMessage: null,
      ibanErrorMessage: null,
      emailErrorMessage: null,
      suggestedCompanyRegistrations: [],
      nameErrorMessage: null,
      registrationNumberErrorMessage: null,
      isDropDownOpen: {
        companyName: false,
        registrationNumber: false,
      },
      cbInstance: window.Chargebee.init({ site: VUE_APP_CHARGEBEE_SITE }),
      hasAddedPayment: false,
    };
  },
  computed: {
    ...mapGetters([
      'isDevFeatureFlagActive',
      'displayPaymentByBankTransfer',
      'displayPaymentByCreditCard',
      'isBillingOnOrganisation',
      'isContractChangeFromSalesforce',
    ]),
    ...mapState(['quote']),

    isAllowedToUpdateCountryField() {
      return this.isDevFeatureFlagActive('FEATUREDEV_INAPP_ROLLOUT_RAUL_COUNTRY') && !this.isContractChangeFromSalesforce;
    },
    countryOptions() {
      return COUNTRY_KEYS.map(country => ({ id: country, text: this.$t(`common.countries.${country}`) }));
    },
    isSubmitDisabled() {
      const isPaymentValid = this.hasAddedPayment ||
        (this.displayPaymentByIban && !!this.iban && !!this.email) ||
        (this.displayPaymentByBankTransfer && !!this.email);
      const isFilled =
        this.address && this.city && this.zipcode && this.country &&
        this.registrationNumber && this.name;
      const hasAnyErrorMessage = !!this.addressErrorMessage || !!this.nameErrorMessage ||
        !!this.cityErrorMessage || !!this.zipcodeErrorMessage || !!this.countryErrorMessage ||
        !!this.registrationNumberErrorMessage || !!this.ibanErrorMessage ||
        !!this.emailErrorMessage;

      return hasAnyErrorMessage || !isFilled || !isPaymentValid;
    },
    // Only INSEE API is implemented to look for company registrations for the moment.
    isSupportedCountryForRegistrationLookup() {
      return this.shopPaymentInfos.billingAddress.country_code === 'FR';
    },
    name: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.shopPaymentInfos.name;
      },
      set(value) {
        this.validatePresence('name');
        this.updatePaymentInfosAttribute({
          attribute: 'name',
          value,
          index: this.shopPaymentInfos.salesforceId,
        });
      },
    },
    address: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.shopPaymentInfos.billingAddress.line1;
      },
      set(value) {
        this.validatePresence('line1');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.shopPaymentInfos.billingAddress, line1: value },
          index: this.shopPaymentInfos.salesforceId,
        });
      },
    },
    city: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.shopPaymentInfos.billingAddress.city;
      },
      set(value) {
        this.validatePresence('city');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.shopPaymentInfos.billingAddress, city: value },
          index: this.shopPaymentInfos.salesforceId,
        });
      },
    },
    country: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.shopPaymentInfos.billingAddress.country_code;
      },
      set(value) {
        this.validatePresence('country');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: {
            ...this.shopPaymentInfos.billingAddress,
            country_code: value,
            country: this.$t(`common.countries.${value}`, 'en'), // Raul expects an english country's value
          },
          index: this.shopPaymentInfos.salesforceId,
        });

        // since the registration number is depending on the country, we have to run again the validation
        this.validateRegistrationNumber();
      },
    },
    zipcode: {
      get() {
        // Raul SHOULD send only one shop in the quote for contract change
        // this is checked in populatePaymentInfos()
        return this.shopPaymentInfos.billingAddress.zip;
      },
      set(value) {
        this.validatePresence('zip');
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.shopPaymentInfos.billingAddress, zip: value },
          index: this.shopPaymentInfos.salesforceId,
        });
      },
    },
    ibanWithSpacesAllowed: {
      get() {
        return this.shopPaymentInfos.iban || null;
      },
      set(value) {
        this.updatePaymentInfosAttribute({
          attribute: 'iban',
          value,
          index: this.shopPaymentInfos.salesforceId,
        });
      },
    },
    email: {
      get() {
        return this.shopPaymentInfos.billingAddress.email || null;
      },
      set(value) {
        this.updatePaymentInfosAttribute({
          attribute: 'billingAddress',
          value: { ...this.shopPaymentInfos.billingAddress, email: value },
          index: this.shopPaymentInfos.salesforceId,
        });
      },
    },
    registrationNumber: {
      get() {
        return this.registrationNumberWithSpacesAllowed &&
          removeWhiteSpaces(this.registrationNumberWithSpacesAllowed);
      },
      set(value) {
        this.validateRegistrationNumber();
        this.registrationNumberWithSpacesAllowed = value;
        this.updatePaymentInfosAttribute({
          attribute: 'registrationNumber',
          value: this.registrationNumber,
          index: this.shopPaymentInfos.salesforceId,
        });
      },
    },
    showCompanyNameDropdown() {
      return this.suggestedCompanyRegistrations.length > 0 &&
        this.isDropDownOpen.companyName;
    },
    showRegistrationNumberDropdown() {
      return this.suggestedCompanyRegistrations.length > 0 &&
        this.isDropDownOpen.registrationNumber;
    },
    billingAddressLabel() {
      return this.isBillingOnOrganisation ?
        this.$t('create_shop.billing_address_label.billing_on_orga') :
        this.$t('create_shop.billing_address_label.billing_on_shop');
    },
    chargebeeId() {
      return this.shopPaymentInfos.paymentOwnerChargebeeId;
    },
    paymentButtonLabel() {
      return this.hasAddedPayment ? this.$t('create_shop.payment_button.modify_payment') : this.$t('create_shop.payment_button.add_payment');
    },
    displayPaymentByIban() {
      return this.quote.payment_method === 'Direct debit';
    },
    iban() {
      return this.ibanWithSpacesAllowed && removeWhiteSpaces(this.ibanWithSpacesAllowed);
    },
  },
  watch: {
    isSubmitDisabled(newValue) {
      this.$emit('toggle-submit', newValue);
    },
  },
  mounted() {
    if (!this.isContractChangeFromSalesforce) this.$emit('toggle-submit', true);
    this.registrationNumber = this.shopPaymentInfos.registrationNumber || null;
    this.address = this.shopPaymentInfos.billingAddress?.line1 || null;
    this.city = this.shopPaymentInfos.billingAddress?.city || null;
    this.zipcode = this.shopPaymentInfos.billingAddress?.zip || null;
    this.country = this.shopPaymentInfos.billingAddress?.country_code || null;

    this.hasAddedPayment = this.shopPaymentInfos.hasValidPaymentMethod ||
      this.isBillingOnOrganisation;
  },
  methods: {
    ...mapActions(['updatePaymentInfosAttribute']),

    validateMandatoryField(attribute) {
      const value = this[attribute];

      if (value === '') {
        this[`${attribute}ErrorMessage`] = this.$t('fill_billing_info.billing_line.error_input_mandatory');
      } else {
        this[`${attribute}ErrorMessage`] = null;
      }
    },
    // for the companyName "L'AMI DU PAIN" with 'AMI D' as input, we return the array:
    // [{ text: "L'" },
    //  { text: 'AMI D', isHighlighted: true },
    //  { text: 'U PAIN' }]
    //
    highlightMatch(result, searchedText) {
      const matching = result.toLowerCase().match(searchedText.toLowerCase());
      if (!matching) return [{ text: result }];

      const formattedText = [
        { text: searchedText, isHighlighted: true },
        { text: result.substring(matching.index + searchedText.length) },
      ];

      if (matching.index > 0) {
        formattedText.unshift({ text: result.substring(0, matching.index) });
      }

      return formattedText;
    },
    clearSuggestedCompanyRegistrations() {
      this.suggestedCompanyRegistrations = [];
      this.isDropDownOpen.registrationNumber = false;
      this.isDropDownOpen.companyName = false;
      this.$refs.companyNameDropdown.hide();
      this.$refs.registrationNumberDropdown.hide();
    },
    handleNameChanges() {
      this.validatePresence('name');
      if (!this.isSupportedCountryForRegistrationLookup) return;
      if (this.name.length < 3) {
        this.clearSuggestedCompanyRegistrations();
        return;
      }

      this.lookupCompanyRegistrationsByName()
        .then(response => {
          this.populateSuggestedCompanyRegistrations(response, this.name);
          this.isDropDownOpen.companyName = true;
          this.isDropDownOpen.registrationNumber = false;
          this.$refs.companyNameDropdown.open();
          this.$refs.registrationNumberDropdown.hide();
        }).catch(this.clearSuggestedCompanyRegistrations);
    },
    populateSuggestedCompanyRegistrations(suggestedCompanies, searchedText) {
      this.suggestedCompanyRegistrations = suggestedCompanies.map(suggestedCompany => ({
        address: suggestedCompany.address,
        city: suggestedCompany.city,
        creationDate: suggestedCompany.creationDate,
        shopRegistrationNumber: suggestedCompany.shopRegistrationNumber,
        displayLabel: this.highlightMatch(
          suggestedCompany.name,
          searchedText,
        ),
        // We need to remove the dot in the main activity to be able to use the naf filter
        // Dots are added by the INSEE API
        mainActivity: getNaf(suggestedCompany.mainActivity.replaceAll('.', '')),
        name: suggestedCompany.name,
        organisationRegistrationNumber: suggestedCompany.organisationRegistrationNumber,
        zipCode: suggestedCompany.zipCode,
      }));
    },
    handleAndValidateRegistrationNumber() {
      this.handleRegistrationNumberInput();
      this.validateRegistrationNumber();
    },
    handleRegistrationNumberInput() {
      if (!this.isSupportedCountryForRegistrationLookup) return;
      if (this.registrationNumber.length < 3) {
        this.clearSuggestedCompanyRegistrations();
        return;
      }

      this.lookupCompanyRegistrationsByRegistrationNumber()
        .then(response => {
          this.populateSuggestedCompanyRegistrations(response, '');
          this.isDropDownOpen.registrationNumber = true;
          this.isDropDownOpen.companyName = false;
          this.$refs.registrationNumberDropdown.open();
          this.$refs.companyNameDropdown.hide();
        }).catch(this.clearSuggestedCompanyRegistrations);
    },
    validateRegistrationNumber() {
      this.validatePresence('registrationNumber');
      if (!this.registrationNumber || !this.isSupportedCountryForRegistrationLookup) return;

      if (!isValidSiret(this.registrationNumber)) {
        this.registrationNumberErrorMessage = this.$t('fill_billing_info.billing_line.siret_invalid');
      }
    },
    validatePresence(attribute) {
      const value = this[attribute];

      if (value === '') {
        this[`${attribute}ErrorMessage`] = this.$t('fill_billing_info.billing_line.error_input_mandatory');
      } else {
        this[`${attribute}ErrorMessage`] = null;
      }
    },
    lookupCompanyRegistrationsByName() {
      return billingAutomationClient
        .getCompanyRegistrationsByName(this.name);
    },
    lookupCompanyRegistrationsByRegistrationNumber() {
      return billingAutomationClient
        .getCompanyRegistrationsByShopRegistrationNumber(this.registrationNumber);
    },
    openCheckout() {
      window.addEventListener('message', this.handleMessageReceived);
      // 'this' unavailable inside openCheckout function
      const vueComponent = this;
      try {
        this.cbInstance.openCheckout({
          hostedPage() {
            return billingAutomationClient.createCheckoutByChargebeeIdAndBillingEntity(
              vueComponent.chargebeeId,
              vueComponent.quote.billing_entity,
            );
          },
          close() {
            window.removeEventListener('message', vueComponent.handleMessageReceived);
          },
          error(error) {
            vueComponent.showCheckoutOpenErrorToast();
          },
        });
      } catch (error) {
        this.cbInstance.closeAll();
        this.showCheckoutOpenErrorToast();
      }
    },
    showCheckoutOpenErrorToast() {
      this.makeAlertToast(this.$t('fill_billing_info.billing_line.checkout_open_error'));
    },
    handleMessageReceived(evt) {
      // evt.data is either Object, or a simple name string
      if (evt?.data?.key !== 'cb.payment_source.add') return;

      if (evt?.data?.status === 'error') {
        this.hasAddedPayment = false;
        this.showBillingErrorToast();
      } else {
        this.hasAddedPayment = true;
      }
    },
    showBillingErrorToast() {
      this.makeAlertToast(this.$t('fill_billing_info.billing_line.billing_error'));
    },
    validateIban() {
      this.validateMandatoryField('iban');
      if (this.iban && !isValidIban(this.iban)) {
        this.ibanErrorMessage = this.$t('fill_billing_info.billing_line.iban_invalid');
      }
    },
    autofillBillingInfos(company) {
      this.name = company.name;
      this.registrationNumber = company.shopRegistrationNumber;
      this.address = company.address;
      this.city = company.city;
      this.zipcode = company.zipCode;
      this.clearSuggestedCompanyRegistrations();
    },
    validateEmail() {
      this.validateMandatoryField('email');
      if (this.email && !isValidEmail(this.email)) {
        this.emailErrorMessage = this.$t('fill_billing_info.billing_line.email_invalid');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-shop__label {
  display: flex;
  margin: 24px 0 16px;
  line-height: 17px;
  gap: 12px;
}

.input-row__line-container {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.input-row--item {
  flex-grow: 1;
}

.input-row__second-line {
  max-width: 100%;
}

.create-shop__body__input-row {
  margin: 16px 0;

  .billing-address__label {
    margin: 32px 0 16px 0;
  }
}

.create-shop__payment {
  margin-bottom: 16px;
}

.create-shop__payment-label {
  display: flex;
  align-items: center;
  color: $sk-blue-50;
  gap: 4px;
}
</style>
