<template>
  <div>
    <ContractChangeForm
      :current-step="currentStep"
      :is-create-shop-loading="isCreateShopLoading"
      :is-submit-disabled="isSubmitDisabled"
      @submit="submitStep"
    >
      <RouterView
        :is-create-shop-loading="isCreateShopLoading"
        @redirect-to-skello="redirectUserToSkello"
        @disable-submit="handleDisableSubmit"
        @enable-submit="handleEnableSubmit"
      />
    </ContractChangeForm>
  </div>
</template>

<script>
import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import ContractChangeForm from '@components/ContractChangeForm';
import { skelloHttpClient } from '@/shared/utils/clients/skelloHttpClient';
import WebsocketClient from '@/shared/utils/clients/websocketClient';
import { VUE_APP_SKELLO_API_URL } from '@/shared/config';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import {
  organisationIsBillable,
  isBillable,
  isCustomerPaymentInfoCompleted,
} from '@/shared/utils/method_helper';

export default {
  name: 'ContractChange',
  components: { ContractChangeForm },
  data() {
    return {
      isCreateShopLoading: false,
      isSubmitDisabled: false,
      websocketClient: null,
    };
  },
  computed: {
    ...mapState(['quote', 'password', 'shopsPaymentInfos']),
    ...mapGetters(['displayPaymentByCreditCard', 'isBillingOnOrganisation', 'isContractChangeFromSalesforce']),
    billingInfoCompletedForCreditCardPayment() {
      if (this.isBillingOnOrganisation) {
        return isCustomerPaymentInfoCompleted(this.quote.organisation);
      }

      return this.quote.organisation.shops.every(isCustomerPaymentInfoCompleted);
    },
    billingInfoCompleted() {
      if (!this.quote.organisation) return false;

      const organisation = this.quote.organisation;

      if (this.displayPaymentByCreditCard) {
        return this.billingInfoCompletedForCreditCardPayment;
      }

      const hasShopAwaitingPayment =
        organisation.shops
          .filter(isBillable)
          .find(shop => !shop.has_valid_payment_method);
      // If Organisation not billable, then we don't check if has_valid_payment_method
      const hasOrganisationAwaitingPayment =
        organisationIsBillable(this.quote.organisation) && !organisation.has_valid_payment_method;
      return !hasShopAwaitingPayment && !hasOrganisationAwaitingPayment;
    },
    isQuoteInvalid() {
      return !this.quote ||
        !this.quote.organisation ||
        this.isQuoteExpired ||
        !['Sent', 'Presented', 'Accepted'].includes(this.quote.status);
    },
    isQuoteExpired() {
      const expirationDate = new Date(this.quote.valid_till);
      if (!expirationDate) return false;

      // JS counts in milliseconds, other programs in seconds
      // In order to avoid float bugs we convert up, not down
      return new Date() > expirationDate;
    },
    currentStep() {
      return this.contractChangeSteps.find(step => step.routerName === this.$route.name);
    },
    totalStepCount() {
      // if billingInfoCompleted is true in case of contract change, the flow contains one less step
      if (this.billingInfoCompleted) return this.contractChangeSteps.length - 3;

      // no need to count the error and loadingProvisioning step
      return this.contractChangeSteps.length - 2;
    },
    // Needs to be a computed to reload on i18n lang change
    contractChangeSteps() {
      return [
        {
          title: this.createShopTitle,
          routerName: 'createShop',
          sidebarImg: this.sidebarImgLink('CreateShop'),
          stepIndex: 1,
          submitAction: this.submitProvisioning,
          submitLabel: this.$t('validate_quote.submit_label'),
        },
        {
          title: this.$t('step_error.title'),
          routerName: 'contractChangeError',
          sidebarImg: this.sidebarImgLink('CreateShop'),
          stepCountHidden: true,
          submitAction: async () => { },
          submitHidden: true,
        },
        {
          title: this.$t('success_page.title'),
          routerName: 'successPage',
          sidebarImg: this.sidebarImgLink('CreateShop'),
          stepCountHidden: true,
          submitAction: this.redirectUserToSkello,
          submitLabel: this.$t('success_page.submit_label'),
          submitHidden: false,
        },
      ];
    },
    createShopTitle() {
      if (this.isCreateShopLoading) {
        return this.$t('create_shop.create_shop_loader.title');
      }

      return this.isContractChangeFromSalesforce ? this.$t('fill_billing_info.title') : this.$t('create_shop.title');
    },
    quoteId() {
      return this.$route.query.id;
    },
  },
  created() {
    if (this.$route.name === 'contractChangeError') return;

    if (this.$route.name !== this.getCurrentStep()) {
      this.$router.push({ name: this.getCurrentStep(), query: this.$route.query });
    }

    if (this.isContractChangeFromSalesforce) {
      this.isSubmitDisabled = true;
    }

    // If the authentication through user's session is disabled on the monolith then we need to
    // get a token in order to identify the user when redirecting. If the flow is from Salesforce, the URL will not
    // contain the token in the query string, and we cannot redirect the user to the admin page after the contract change
    if (!this.isContractChangeFromSalesforce) {
      this.getStatelessToken();
    }
  },
  destroyed() {
    if (this.websocketClient) {
      this.websocketClient.disconnect();
    }
  },
  methods: {
    ...mapActions([
      'validateQuote',
      'completeCustomerPaymentInfos',
      'fetchQuote',
      'createChargebeeSource',
    ]),
    ...mapMutations(['setProvisioningAction']),
    redirectToErrorPage(errorType) {
      this.$router.push({ name: 'error', query: this.$route.query, params: { error: errorType } });
    },
    sidebarImgLink(step) {
      return this.sidebarAssetsPath()[step];
    },
    sidebarAssetsPath() {
      return {
        CreateShop: new URL('@assets/sidebar-images/CreateShop.svg', import.meta.url).href,
      };
    },
    handleDisableSubmit() {
      this.isSubmitDisabled = true;
    },
    handleEnableSubmit() {
      this.isSubmitDisabled = false;
    },
    submitStep() {
      this.currentStep.submitAction()
        .then(() => {
          this.handleEnableSubmit();
          if (this.getCurrentStep() !== this.$route.name) {
            this.$router.push({
              name: this.getCurrentStep(),
              query: this.$route.query,
            });
          }
        })
        .catch(error => {
          let message = this.$t('common.error_message');
          if (error?.response?.data?.message === 'Email already taken') {
            message = this.$t('step_error.email_error', { email: this.quote.organisation.contacts[0].email });
          }
          this.makeAlertToast(message);
        });
    },
    getCurrentStep() {
      if (!['Sent', 'Presented', 'Accepted'].includes(this.quote.status)) return 'contractChangeError';

      return 'createShop';
    },
    async submitProvisioning() {
      this.isCreateShopLoading = true;

      if (this.isContractChangeFromSalesforce) {
        await billingAutomationClient.startContractChange(this.quote.id);
      } else {
        if (this.displayPaymentByCreditCard) {
          // send billing info to Chargebee
          await billingAutomationClient
            .createChargebeeSource(
              this.shopsPaymentInfos[0].paymentOwnerChargebeeId,
              this.shopsPaymentInfos[0].billingAddress.city,
              this.shopsPaymentInfos[0].billingAddress.country_code,
              this.shopsPaymentInfos[0].billingAddress.email,
              this.shopsPaymentInfos[0].billingAddress.first_name,
              '', // iban
              this.shopsPaymentInfos[0].billingAddress.last_name,
              this.shopsPaymentInfos[0].billingAddress.line1,
              this.shopsPaymentInfos[0].billingAddress.zip,
            );

          // update quote to Raul
          await this.completeCustomerPaymentInfos(this.shopsPaymentInfos[0]);
        } else {
          // send billing info to Chargebee and update quote to Raul
          await this.createChargebeeSource(this.shopsPaymentInfos[0]);
        }

        await billingAutomationClient
          .updateOrganisation({
            quoteId: this.quote.id,
            token: this.$route.query.t,
            sendEmail: true,
            parentClusterNodeId: this.$route.query.c,
          });
      }

      this.websocketClient = new WebsocketClient({ type: 'Onboarding', uuid: btoa(this.quote.id) });
      this.websocketClient.connect(async event => {
        if (event.data === 'pingToQuoteToRedirect') {
          // need to refresh the quote so that we have the id of the new shop
          await this.fetchQuote(this.quoteId);
          this.isCreateShopLoading = false;
          this.$router.push({
            path: '/contract_change/success_page',
            query: this.$route.query,
          });
        } else {
          console.error('Websocket error: unknow message', { event });
        }
      });
      setTimeout(() => {
        this.redirectUserToSkello();
      }, 60000);
    },
    getShopId(shop) {
      const id = shop.SK_shop_id;
      // to be doubly sure - sometimes we have "o.<shopId>" and "<shopId>"
      return id && id.includes('.') ? id.split('.')[1] : id;
    },

    redirectUserToSkello() {
      if (['error', 'contractChangeError'].includes(this.$route.name)) return;

      const baseUrl = VUE_APP_SKELLO_API_URL;
      const shopId = this.getShopId(this.quote.organisation.shops[0]);

      // For the contract change from in-app, we can use the stateless token to redirect the user to the admin page
      window.location.href = this.isContractChangeFromSalesforce ?
        baseUrl :
        `${baseUrl}/v3/shops/${shopId}/admin-onboarding?needs_jwt=true&roll_out=${this.tempToken}`;
    },
    getStatelessToken() {
      skelloHttpClient.get('/v3/users/sessions/stateless_token/roll_out', {
        headers: {
          Authorization: `Bearer ${this.$route.query.t}`,
        },
        validateStatus: status => status === 200,
      }).then(response => {
        if (!response.data?.token) {
          throw new Error('Cannot fetch stateless token from skello app');
        }
        this.tempToken = response.data.token;
      }).catch(error => {
        this.makeAlertToast(this.$t('common.error_message'));
        throw error;
      });
    },
  },
};
</script>

<style lang="scss">
// Application-wide css
* {
  box-sizing: border-box;

  // Recenters the tick in the checkbox
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

#toast-portal {
  position: relative;
  z-index: 101;

  +#cb-container {
    // Chargebee set their modal z-index at the highest possible value (2'147'483'647)
    // We need our error toasters over it (and in general, it's not great practice)
    // so we set it to a high, but more manageable value
    z-index: 100 !important;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  padding-top: 25vh;
  width: 100%;
}

.spin-loading {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: .25rem solid rgba(0, 186, 218, .2);
  border-top-color: #00bada;
  animation: spin 1s infinite linear;
}

.spin-loading.loading--double {
  border-style: dotted;
  border-width: 5px;
}

</style>
