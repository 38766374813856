<template>
  <SkCollapse
    ref="collapse"
    class="shop-details__collapse"
    :disabled="isFormSubmitted"
    :opened-by-default="true"
  >
    <template slot="labelLeft">
      <div class="shop-details__title">
        {{ shopPaymentInfos.name }}
      </div>
    </template>

    <template slot="labelRight">
      <div :class="shopDetailsStatusClass">
        {{ shopDetailsStatusText }}
      </div>
    </template>

    <div
      slot="content"
      class="shop-details__content"
    >
      <ShopDetailsForm
        ref="shopDetailsForm"
        :shop-payment-infos="shopPaymentInfos"
        @toggle-submit="toggleSubmit"
      />
      <div class="shop-details__confirm-container">
        <SkOroraButton
          data-test="shop-details-confirm"
          :disabled="isSubmitDisabled"
          :loading="isLoading"
          @click="handleSubmit"
        >
          {{ $t('create_shop.shop_details.confirm') }}
        </SkOroraButton>
      </div>
    </div>
  </SkCollapse>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { SkCollapse } from '@skelloapp/skello-ui';
import ShopDetailsForm from '@components/ShopDetailsForm';
import { billingAutomationClient } from '@/shared/utils/clients/billingAutomationClient';
import { UPDATE_BILLING_INFO_FAILED } from '@/shared/constants/errors';

export default {
  name: 'CollapsableShopDetailsForm',
  components: {
    SkCollapse,
    ShopDetailsForm,
  },
  props: {
    shopPaymentInfos: {
      type: Object,
      required: true,
    },
    isFormSubmitted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSubmitDisabled: true,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['quote']),
    ...mapGetters(['displayPaymentByCreditCard', 'isBillingOnOrganisation']),
    shopDetailsStatusClass() {
      return {
        'shop-details__status': true,
        'shop-details__status--complete': this.isFormSubmitted,
        'shop-details__status--pending': !this.isFormSubmitted,
      };
    },
    shopDetailsStatusText() {
      const key = this.isFormSubmitted ? 'complete' : 'pending';
      return this.$t(`create_shop.shop_details.status.${key}`);
    },
  },
  methods: {
    ...mapActions(['createChargebeeSource', 'completeCustomerPaymentInfos']),
    toggleSubmit(isDisabled) {
      this.isSubmitDisabled = isDisabled;
    },
    toggleCollapse() {
      if (this.$refs.collapse && this.$refs.collapse.$el) {
        this.$refs.collapse.switchCollapseState();
      }
    },
    redirectToErrorPage(errorType, errorNameComponent) {
      if (this.loadingQuote) this.loadingQuote = false;
      this.$router.push(
        { name: errorNameComponent, query: this.$route.query, params: { error: errorType } },
      );
    },
    async handleSubmit() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        if (!this.isBillingOnOrganisation) {
          if (this.displayPaymentByCreditCard) {
            // send billing info to Chargebee
            await billingAutomationClient
              .createChargebeeSource(
                this.shopPaymentInfos.paymentOwnerChargebeeId,
                this.shopPaymentInfos.billingAddress.city,
                this.shopPaymentInfos.billingAddress.country_code,
                this.shopPaymentInfos.billingAddress.email,
                this.shopPaymentInfos.billingAddress.first_name,
                '', // iban
                this.shopPaymentInfos.billingAddress.last_name,
                this.shopPaymentInfos.billingAddress.line1,
                this.shopPaymentInfos.billingAddress.zip,
              );
          } else {
            // send billing info to Chargebee and update quote to Raul
            await this.createChargebeeSource(this.shopPaymentInfos);
          }
        }

        // update quote to Raul
        await this.completeCustomerPaymentInfos(this.shopPaymentInfos);
      } catch (error) {
        this.redirectToErrorPage(UPDATE_BILLING_INFO_FAILED, 'contractChangeError');
      } finally {
        this.isLoading = false;
      }

      this.onFormSubmitted();
      this.isLoading = false;
    },
    onFormSubmitted() {
      this.$emit('form-submitted');
      this.toggleCollapse();
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-details__collapse {
  border: 1px solid $sk-grey-10;
  border-radius: 8px;
  margin-bottom: 16px;
}

.shop-details__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-left: 8px;
}

.shop-details__status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 16px;

  &--complete {
    color: $sk-success-50;
    background-color: $sk-success-10;
  }
  &--pending {
    color: $sk-grey-50;
    background-color: $sk-grey-5;
  }
}

.shop-details__content {
  padding: 24px;
}

.shop-details__confirm-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
</style>
