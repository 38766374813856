<template>
  <ProvisioningLoader v-if="isProvisioningLoading" />
  <div v-else>
    <div class="subtitle">
      {{ $t('create_password.subtitle') }}
    </div>
    <div class="email-wrapper">
      <div class="email-subtitle">
        {{ $t('create_password.email_subtitle') }}
      </div>
      <div class="email-input">
        <SkInput
          v-model="quote.organisation.contacts[0].email"
          type="email"
          data-test="email-input"
          disabled
          :label="$t('create_password.email_label')"
        />
      </div>
    </div>
    <div class="password-wrapper">
      <SkPassword
        ref="password"
        v-model="localPassword"
        data-test="password-input"
      />
    </div>
    <div class="consent-input">
      <SkCheckBox
        id="consent-check-box"
        v-model="consent"
        data-test="consent-input"
      />
      <!-- eslint-disable vue/no-v-html -->
      <span
        class="consent-input-label"
        v-html="$t('create_password.consent_label')"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
} from 'vuex';
import {
  SkInput,
  SkCheckBox,
  SkPassword,
} from '@skelloapp/skello-ui';
import ProvisioningLoader from '@views/ProvisioningLoader';

export default {
  name: 'CreatePassword',
  components: {
    SkInput,
    SkCheckBox,
    SkPassword,
    ProvisioningLoader,
  },
  props: {
    isProvisioningLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      consent: false,
      isPasswordValid: false,
    };
  },
  computed: {
    ...mapState(['quote', 'password']),
    isFormValid() {
      return this.isPasswordValid && this.consent;
    },
    localPassword: {
      get() {
        return this.password;
      },
      set(newValue) {
        this.setPassword(newValue);
      },
    },
  },
  watch: {
    isFormValid(newValue) {
      this.$emit(newValue ? 'enable-submit' : 'disable-submit');
    },
  },
  mounted() {
    this.$emit('disable-submit');
    this.$watch(
      '$refs.password.isPasswordValid',
      isPasswordValid => { this.isPasswordValid = isPasswordValid; },
    );
  },
  methods: {
    ...mapMutations(['setPassword']),
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 16px;
  margin-bottom: 24px;
}

.email-subtitle {
  margin-bottom: 8px;
}

.email-input {
  max-width: 367px;
}

.password-wrapper {
  margin-top: 24px;
}

.consent-input {
  align-items: center;
  display: flex;
  margin-top: 27px;
}

.consent-input-label {
  margin-left: 8px;
}
</style>

<style lang="scss">
.sk-password__validation-container {
  height: auto !important;
  flex-wrap: wrap !important;
}

.sk-password__validation-container .sk-password__validation-step {
  display: flex !important;
  align-items: center !important;
}

.sk-password__validation-container .sk-password__validation-step {
  margin-bottom: 0px !important;
}
</style>
