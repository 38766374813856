<template>
  <CreateShopLoader v-if="isCreateShopLoading" />
  <div v-else>
    <GlobalEvents
      @keyup.escape="clearSuggestedCompanyRegistrations"
      @click="handleClickOutside"
    />
    <div
      v-if="isContractChangeFromSalesforce"
      class="collapsable-shop-content"
    >
      <CollapsableShopDetailsForm
        v-for="(shopInfo, index) in nonCreatedShopPaymentInfos"
        :key="shopInfo.salesforceId || index"
        ref="collapsableShopDetailsForm"
        :shop-payment-infos="shopInfo"
        :is-form-submitted="shopInfo.isFormSubmitted"
        @form-submitted="() => onShopFormSubmitted(index)"
      />
    </div>
    <template v-else>
      <UpsellBanner
        class="upsell-banner__margin"
        :description="upsellBannerDescription"
      />
      <ShopDetailsForm
        ref="shopDetailsForm"
        :shop-payment-infos="shopsPaymentInfos[0]"
        @toggle-submit="toggleSubmit"
      />
    </template>
    <div v-if="!isBillingOnOrganisation">
      <p class="sk-text-x-small-regular">
        {{ $t('create_shop.disclamer') }}
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="sk-text-x-small-regular"
      >
        <i18n
          path="create_shop.terms_of_service"
        >
          <template #linkTerms>
            <SkOroraLink
              target="_blank"
              rel="noopener noreferrer"
              size="x-small"
              :href="$t('create_shop.link_terms_of_service')"
            >
              {{ $t('create_shop.text_terms_of_service') }}
            </SkOroraLink>
          </template>
          <template #linkPrivacy>
            <SkOroraLink
              target="_blank"
              rel="noopener noreferrer"
              size="x-small"
              :href="$t('create_shop.link_privacy_policy')"
            >
              {{ $t('create_shop.text_privacy_policy') }}
            </SkOroraLink>
          </template>
        </i18n>
      </p>
    <!-- eslint-enable vue/no-v-html -->
    </div>
    <div v-if="displayPaymentByBankTransfer">
      <p class="sk-text-large-semibold bank-transfer-payment-label">
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method') }}
      </p>
      <p class="sk-text-medium-regular bank-transfert-description ">
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_label') }}
        <span class="span-bank-transfer">
          {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_span') }}
        </span>
        <br>
        {{ $t('fill_billing_info.billing_line.bank_transfer.payment_method_description') }}
      </p>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import GlobalEvents from 'vue-global-events';

import UpsellBanner from '@components/UpsellBanner';
import ShopDetailsForm from '@components/ShopDetailsForm';
import CreateShopLoader from '@views/CreateShopLoader';
import CollapsableShopDetailsForm from '@components/CollapsableShopDetailsForm/index.vue';
import dayjs from 'dayjs';
import { extractPrice } from '@/shared/utils/method_helper';

export default {
  name: 'CreateShop',
  components: {
    CollapsableShopDetailsForm,
    CreateShopLoader,
    GlobalEvents,
    UpsellBanner,
    ShopDetailsForm,
  },
  props: {
    isCreateShopLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['isBillingOnOrganisation', 'displayPaymentByBankTransfer', 'isContractChangeFromSalesforce']),
    ...mapState(['quote', 'shopsPaymentInfos']),

    newPackDate() {
      return dayjs(this.quote.contract_terms.contract_term_start).locale(this.$i18n.locale).format('DD MMMM YYYY');
    },
    addedPrice() {
      return extractPrice(this.quote, this.quote.totals.total_amount);
    },
    packName() {
      return this.quote.quote_line_items.find(elem => elem.type === 'Pack')?.title;
    },
    listOption() {
      return this.quote.quote_line_items.filter(elem => elem.type.includes('Option')).map(elem => elem.title);
    },
    lastOption() {
      return this.listOption[this.listOption.length - 1];
    },
    nonCreatedShopPaymentInfos() {
      return this.shopsPaymentInfos.filter(shop => !shop.SK_shop_id);
    },
    translateListOptionWithoutLastElement() {
      if (this.listOption.length === 1) {
        return this.listOption[0];
      }
      return this.listOption.slice(0, this.listOption.length - 1).join(', ');
    },
    upsellBannerTranslateParam() {
      return {
        start_date: this.newPackDate,
        pack_name: this.packName,
        options: this.translateListOptionWithoutLastElement,
        price: this.addedPrice,
        last_option: this.lastOption,
      };
    },
    upsellBannerDescription() {
      const key = this.quote.type_of_payment === 'Shop' ? 'shop' : 'organisation';
      const engagmentUnit = this.quote.contract_terms.total_engagement_unit;

      return this.$tc(`upsell_banner_${engagmentUnit}.${key}_payment_description`, this.listOption.length, this.upsellBannerTranslateParam);
    },
  },
  methods: {
    ...mapMutations(['setShopFormSubmitted']),
    handleClickOutside(event) {
      if (!this.isContractChangeFromSalesforce) {
        if (this.$refs.shopDetailsForm.$refs.dropdown?.$el?.contains(event.target)) return;

        this.$refs.shopDetailsForm.clearSuggestedCompanyRegistrations();
        return;
      }

      const collapsableForms = this.$refs.collapsableShopDetailsForm;
      if (collapsableForms) {
        if (collapsableForms.some(
          form => form.$refs.shopDetailsForm?.$refs.dropdown?.$el?.contains(event.target))
        ) return;

        collapsableForms.forEach(form => {
          if (form.$refs.shopDetailsForm) {
            form.$refs.shopDetailsForm.clearSuggestedCompanyRegistrations();
          }
        });
      }
    },
    clearSuggestedCompanyRegistrations() {
      if (!this.isContractChangeFromSalesforce) {
        this.$refs.shopDetailsForm.clearSuggestedCompanyRegistrations();
        return;
      }

      const collapsableForms = this.$refs.collapsableShopDetailsForm;
      if (collapsableForms) {
        collapsableForms.forEach(form => {
          if (form.$refs.shopDetailsForm) {
            form.$refs.shopDetailsForm.clearSuggestedCompanyRegistrations();
          }
        });
      }
    },
    toggleSubmit(value) {
      if (value) {
        this.$emit('disable-submit');
      } else {
        this.$emit('enable-submit');
      }
    },
    onShopFormSubmitted(index) {
      const shopIndex = this.shopsPaymentInfos.findIndex(
        shop => shop.salesforceId === this.nonCreatedShopPaymentInfos[index].salesforceId,
      );
      this.setShopFormSubmitted({ index: shopIndex, isFormSubmitted: true });

      if (this.nonCreatedShopPaymentInfos.every(shop => shop.isFormSubmitted)) {
        this.$emit('enable-submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-banner__margin {
  margin-top: 24px;
}

.span-bank-transfer {
  font-weight: 600;
}
.bank-transfert-description {
  margin-top: 16px;
  margin-bottom: 0px;
}
.bank-transfer-payment-label {
  margin-top: 32px;
  margin-bottom: 0px;
}
.collapsable-shop-content {
  margin-top: 24px;
}
</style>
