<template>
  <div>
    <div class="subtitle">
      {{ displayErrorMessage }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  FETCH_FAILED,
  PROVISIONING_FAILED,
  UPDATE_BILLING_INFO_FAILED,
  INVALID_QUOTE,
} from '@/shared/constants/errors';

export default {
  name: 'StepError',
  props: {
    error: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['quote']),
    displayErrorMessage() {
      switch (this.error) {
        case FETCH_FAILED:
          return this.$t('step_error.quote_error');
        case PROVISIONING_FAILED:
          return this.$t('step_error.provisioning_error');
        case UPDATE_BILLING_INFO_FAILED:
          return this.$t('step_error.billing_info_error');
        case INVALID_QUOTE:
          return this.$t('step_error.invalid_quote');
        default:
          return this.$t('step_error.generic_message');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 16px;
  margin-top: 13px;
  margin-bottom: 24px;
}
</style>
